import * as React from 'react';
import { useState } from 'react';
import { ChakraProvider, Drawer, DrawerBody, useToast, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Input, DrawerFooter, useDisclosure, Card, Flex, CardBody, Image, Heading, Text, CardFooter, Badge, Button, Stack, Radio, RadioGroup, Select } from '@chakra-ui/react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Login from './Login';
import './fonteriscada.css';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import InputMask from 'react-input-mask';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/Admin" element={<Admin />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </ChakraProvider>
  )
}

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [rua, setRua] = useState('');
  const [bairro, setBairro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [cep, setCep] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cor, setCor] = useState('');
  const [tamanho, setTamanho] = useState('2');

  const firebaseConfig = {
    apiKey: "AIzaSyCpfqyzworH5qfYEFsyr6kNQ2BMFcIniIE",
    authDomain: "osperdidao-83b04.firebaseapp.com",
    projectId: "osperdidao-83b04",
    storageBucket: "osperdidao-83b04.appspot.com",
    messagingSenderId: "1047314051694",
    appId: "1:1047314051694:web:36806f538d2477ecae6c7f",
    measurementId: "G-V2XBXGSHX6"
  };


  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const tamanhoTextos = {
    '1': 'P',
    '2': 'M',
    '3': 'G',
    '4': 'GG',
    '5': 'EG'
  };

  const toast = useToast();

  const handleSubmit = async () => {
    try {
      const tamanhoSelecionado = tamanhoTextos[tamanho]; 
      // eslint-disable-next-line
      const docRef = await addDoc(collection(db, "pedidos"), {
        nome: nome,
        sobrenome: sobrenome,
        rua: rua,
        bairro: bairro,
        numero: numero,
        complemento: complemento,
        cidade: cidade,
        cep: cep,
        cpf: cpf,
        telefone: telefone,
        cor: cor,
        tamanho: tamanhoSelecionado,
        entregue: false,
        pago: false
      });

      setNome('');
      setSobrenome('');
      setRua('');
      setBairro('');
      setNumero('');
      setComplemento('');
      setCidade('');
      setCep('');
      setCpf('');
      setTelefone('');
      setCor('');
      setTamanho('2');

      toast({
        title: "Pedido enviado com sucesso!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setTimeout(() => {
        window.location.reload();
      }, 5000); 
    } catch (error)  {
      console.error("Erro ao adicionar documento: ", error);
      
      toast({
        title: "Erro ao enviar pedido",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  return (
    <>
    <Flex justify="center" mt="5">
    <Card width="100%" ml={5} mr={5} boxShadow="lg">
      <CardBody mt={2} mb={2}>
        <Image mt={-4} ml={1} boxSize='70px' src='https://i.imgur.com/7xdDokf.png' alt='VitriNni Logo' />
        <Flex justify="right" mt={-5}>
          <ColorModeSwitcher />
        </Flex>
      </CardBody>
    </Card>
  </Flex>

  <Flex justify="center" mt="5">
    <Card maxW='sm' mb={0} ml={5} mr={5} boxShadow="lg">
      <Badge ml='0' colorScheme='red' minH={8}>
        <Flex justify="center">
          <span style={{ fontSize: '20px' }}>10% OFF</span>
        </Flex>
      </Badge>
      <CardBody>
        <Image
          src='https://i.imgur.com/bwzLFVf.png'
          borderRadius='md'
          mt={-49}
        />
        <Stack mt='-3' spacing='3'>
          <Heading size='md'>Os Perdidão | 2024</Heading>
          <Text color='red.400' mt={-4} style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
            - InterReps
          </Text>

          <Text color='red.300' fontSize='1xl' className="texto-riscado" mt={-3}>60,00</Text>
          <Text color='green.300' fontSize='2xl' mt={-5}>
            R$54,00
          </Text>
        </Stack>
      </CardBody>
      <CardFooter>
        <>
          <Button ref={btnRef} mt={-8} variant='solid' colorScheme='red' onClick={onOpen}>
            Comprar
          </Button>
          <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Informações</DrawerHeader>

              <DrawerBody>
                <Input placeholder='Nome' mt={-2} mb={2} value={nome} onChange={(e) => setNome(e.target.value)} />
                <Input placeholder='Sobrenome' mb={2} value={sobrenome} onChange={(e) => setSobrenome(e.target.value)} />
                <Input placeholder='Rua' mb={2} value={rua} onChange={(e) => setRua(e.target.value)} />
                <Input placeholder='Bairro' mb={2} value={bairro} onChange={(e) => setBairro(e.target.value)} />
                <Input placeholder='Numero' mb={2} value={numero} onChange={(e) => setNumero(e.target.value)} />
                <Input placeholder='Complemento' mb={2} value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                <Input placeholder='Cidade' mb={2} value={cidade} onChange={(e) => setCidade(e.target.value)} />
                <Input placeholder='CEP' mb={2} value={cep} onChange={(e) => setCep(e.target.value)} />
                <InputMask mask="999.999.999-99" value={cpf} onChange={(e) => setCpf(e.target.value)}>
      {() => <Input placeholder='CPF' mb={2} />}
    </InputMask>

    <InputMask mask="(99) 99999-9999" value={telefone} onChange={(e) => setTelefone(e.target.value)}>
      {() => <Input placeholder='Celular' mb={2} />}
    </InputMask>

                <Select placeholder='Selecione a cor' mb={2} value={cor} onChange={(e) => setCor(e.target.value)}>
                  <option value='Preta'>Preta</option>
                </Select>

                <RadioGroup defaultValue='2' mt={4} value={tamanho} onChange={(value) => setTamanho(value)}>
                  <Stack spacing={5} direction='row'>
                    <Radio colorScheme='red' value='1'>P</Radio>
                    <Radio colorScheme='red' value='2'>M</Radio>
                    <Radio colorScheme='red' value='3'>G</Radio>
                    <Radio colorScheme='red' value='4'>GG</Radio>
                    <Radio colorScheme='red' value='5'>EG</Radio>
                  </Stack>
                </RadioGroup>
              </DrawerBody>
              <DrawerFooter>
                <Button variant='outline' mr={3} onClick={onClose}>
                  Cancelar
                </Button>
                <Button colorScheme='red' onClick={handleSubmit}>Finalizar</Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      </CardFooter>
    </Card>
  </Flex>
  </>
  );
}

export default App;
