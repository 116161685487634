import React, { useEffect, useState } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, deleteDoc, doc, updateDoc  } from "firebase/firestore"; // Importe deleteDoc e doc
import { ChakraProvider,  Card, Flex, CardBody, Image, Text, Badge, AccordionIcon, AccordionPanel, Box, Accordion, AccordionItem, AccordionButton, Switch, IconButton } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { FaEraser, FaSignOutAlt } from 'react-icons/fa'; // Importe o ícone de borracha
import { getAuth, onAuthStateChanged, signOut  } from "firebase/auth";
import { useNavigate } from 'react-router-dom';


function Admin() {
    const [user, setUser] = useState(null);
    const [pedidos, setPedidos] = useState([]);
    const navigate = useNavigate();
// eslint-disable-next-line
        const firebaseConfig = {
            apiKey: "AIzaSyCpfqyzworH5qfYEFsyr6kNQ2BMFcIniIE",
            authDomain: "osperdidao-83b04.firebaseapp.com",
            projectId: "osperdidao-83b04",
            storageBucket: "osperdidao-83b04.appspot.com",
            messagingSenderId: "1047314051694",
            appId: "1:1047314051694:web:36806f538d2477ecae6c7f",
            measurementId: "G-V2XBXGSHX6"
        };    
        
        const app = initializeApp(firebaseConfig);
        // eslint-disable-next-line
        const db = getFirestore(app);
        const auth = getAuth();

        useEffect(() => {
            // Inicialize o Firebase
            const app = initializeApp(firebaseConfig);
            const db = getFirestore(app);
        
            const fetchData = async () => {
                const data = await getDocs(collection(db, 'pedidos'));
                const pedidosData = data.docs.map(doc => ({ id: doc.id, ...doc.data(), entregue: doc.data().entregue || false })); // Garante que a propriedade entregue esteja inicializada corretamente
                setPedidos(pedidosData);
            }
        
            fetchData();

              // Verifica se há um usuário autenticado
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        // Cleanup
        return () => unsubscribe();
    }, [firebaseConfig, auth]);
    
  // Renderiza o conteúdo somente se houver um usuário autenticado
// Renderiza o conteúdo somente se houver um usuário autenticado
if (!user) {
    // Navega para a página de login
    navigate('/Login');
    // Retorna null para não renderizar o conteúdo do Admin
    return null;
}

        const handleDelete = async (id) => {
            try {
                // Inicialize o Firebase dentro da função
                const app = initializeApp(firebaseConfig);
                const db = getFirestore(app);
                await deleteDoc(doc(db, 'pedidos', id));
                console.log("Registro deletado com sucesso!");
                // Atualizar os pedidos após a exclusão
                const data = await getDocs(collection(db, 'pedidos'));
                setPedidos(data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
            } catch (error) {
                console.error("Erro ao deletar o registro:", error);
            }
        };

        const handleSignOut = async () => {
            try {
                await signOut(auth); // Use a função de signOut do Firebase Auth
                // Redirecione ou faça qualquer outra coisa após o logout
                navigate('/Login');
            } catch (error) {
                console.error("Erro ao fazer logout:", error);
            }
        };

        const handleToggleEntregue = async (id) => {
            try {
                const app = initializeApp(firebaseConfig);
                const db = getFirestore(app);
        

                const pedidoToUpdate = pedidos.find(pedido => pedido.id === id);
        
                // Define o novo valor de "entregue" com base no estado atual do pedido e do Switch
                const updatedEntregue = !pedidoToUpdate.entregue; // Inverte o valor atual
                // Se você quiser definir o valor baseado no estado do Switch
                // const updatedEntregue = !switchChecked;
        
                // Atualiza o valor de "entregue" no Firestore
                const docRef = doc(db, 'pedidos', id);
                await updateDoc(docRef, { entregue: updatedEntregue });
        
                // Atualiza o estado local (pedidos)
                const updatedPedidos = pedidos.map(pedido => {
                    if (pedido.id === id) {
                        return { ...pedido, entregue: updatedEntregue };
                    } else {
                        return pedido;
                    }
                });
        
                setPedidos(updatedPedidos);
        
                console.log("Estado de entrega atualizado com sucesso!");
            } catch (error) {
                console.error("Erro ao atualizar o estado de entrega:", error);
            }
        };
        

        const handleTogglePago = async (id) => {
            try {
                const app = initializeApp(firebaseConfig);
                const db = getFirestore(app);
        
                // Encontra o pedido pelo ID
                const pedidoToUpdate = pedidos.find(pedido => pedido.id === id);
        
                // Define o novo valor de "pago" com base no estado atual do pedido
                const updatedPago = !pedidoToUpdate.pago;
        
                // Atualiza o valor de "pago" no Firestore
                const docRef = doc(db, 'pedidos', id);
                await updateDoc(docRef, { pago: updatedPago });
        
                // Atualiza o estado local (pedidos)
                const updatedPedidos = pedidos.map(pedido => {
                    if (pedido.id === id) {
                        return { ...pedido, pago: updatedPago };
                    } else {
                        return pedido;
                    }
                });
        
                setPedidos(updatedPedidos);
        
                console.log("Estado de pagamento atualizado com sucesso!");
            } catch (error) {
                console.error("Erro ao atualizar o estado de pagamento:", error);
            }
        };
        
    return (

<ChakraProvider>

            <Flex justify="center" mt="5">
                <Card width="100%" ml={5} mr={5} boxShadow="lg">
                    <CardBody mt={2} mb={2}>
                        <Image mt={-4} ml={1} boxSize='70px' src='https://i.imgur.com/7xdDokf.png' alt='VitriNni Logo' />
                        <Flex justify="right" mt={-5}>
                           
                            <ColorModeSwitcher />
                            <IconButton
                        ml={4}
                        mt={-8}
                        variant="outline"
                        colorScheme="red"
                        aria-label="Fazer logout"
                        onClick={handleSignOut} // Adicione a função para fazer logout
                        icon={<FaSignOutAlt />} // Adicione o ícone de logout (você precisa importá-lo)
                    />
                        </Flex>

                    </CardBody>
                </Card>
            </Flex>
            <Flex justify="center" mt="5">
                <Card width="100%" ml={5} mr={5} mb={3} boxShadow="lg">
                    <CardBody>
                        <Accordion>
                            {pedidos.map((pedido, index) => (
                                <AccordionItem key={pedido.id}>
                                    <h2>
                                    <AccordionButton>
              <Box as="span" flex='1' textAlign='left'>
                <Text fontSize="lg" fontWeight="bold"> Pedido #{index + 1} - {pedido.nome} {pedido.sobrenome}</Text>
              </Box>

            
              <IconButton
    mr={4}
    variant="outline"
    colorScheme="red"
    onClick={() => handleDelete(pedido.id)}
    size="sm"
    icon={<FaEraser />} // Ícone de borracha
/> <AccordionIcon />
            </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <Flex direction="column" borderWidth="1px" borderRadius="lg" overflow="hidden">
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold">Nome</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.nome}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Sobrenome</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.sobrenome}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Rua</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.rua}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Bairro</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.bairro}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Numero</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.numero}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Complemento</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.complemento}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Cidade</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.cidade}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >CEP</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.cep}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >CPF</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.cpf}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Telefone</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.telefone}</Text>

                                            </Box>

                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Cor</Text>

                                            </Box></Badge>
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.cor}</Text>

                                            </Box>
                                            <Badge colorScheme='red'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Tamanho</Text>

                                            </Box></Badge>
                                            
                                            <Box p="2" borderBottomWidth="1px">
                                                <Text fontWeight="bold" fontStyle="italic">{pedido.tamanho}</Text>

                                            </Box>

                                            <Badge colorScheme='yellow'><Box p="2" borderBottomWidth="1px">

                                                <Text fontWeight="bold" >Situacao</Text>

                                            </Box></Badge>
                                            
                                            <Box p="2" borderBottomWidth="1px">

                                            <Text fontWeight="bold"  fontStyle="italic">Pago: 
 <Switch
  isChecked={pedido.pago} 
  onChange={() => handleTogglePago(pedido.id)} 
  ml={2}
  variant="outline"
  colorScheme="green"
  size="sm"
  /></Text>

                                            <Text fontWeight="bold"  fontStyle="italic">Entregue: 
 <Switch
  isChecked={pedido.entregue}
  onChange={() => handleToggleEntregue(pedido.id)} 
  ml={2}
  variant="outline"
  colorScheme="green"
  size="sm"
  /></Text>



                  
                                            </Box>


                                        </Flex>
                                    </AccordionPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </CardBody>
                </Card>
            </Flex>

            
        </ChakraProvider>


    );
}

export default Admin;
