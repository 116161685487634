  
        import React, { useState } from 'react';
        import { initializeApp } from "firebase/app";
        import { getFirestore } from "firebase/firestore";
        import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
        import { ChakraProvider,  Card, Flex, CardBody, Image, Input, FormControl, FormLabel,Button } from '@chakra-ui/react';
        import { ColorModeSwitcher } from './ColorModeSwitcher';
          import { useNavigate  } from 'react-router-dom';
        
        const firebaseConfig = {
            apiKey: "AIzaSyCpfqyzworH5qfYEFsyr6kNQ2BMFcIniIE",
            authDomain: "osperdidao-83b04.firebaseapp.com",
            projectId: "osperdidao-83b04",
            storageBucket: "osperdidao-83b04.appspot.com",
            messagingSenderId: "1047314051694",
            appId: "1:1047314051694:web:36806f538d2477ecae6c7f",
            measurementId: "G-V2XBXGSHX6"
        };    
        
        const app = initializeApp(firebaseConfig);
        // eslint-disable-next-line
        const db = getFirestore(app);
        const auth = getAuth();
        
        function Login() {
            const [email, setEmail] = useState('');
            const [password, setPassword] = useState('');
            // eslint-disable-next-line
            const [error, setError] = useState('');
            const navigate = useNavigate();
        
            const handleLogin = async () => {
                try {
                    await signInWithEmailAndPassword(auth, email, password);
                    navigate('/Admin');
                } catch (error) {
                    setError(error.message);
                }
            };
        
            return (
                <ChakraProvider>

<Flex justify="center" mt="5">
                <Card width="100%" ml={5} mr={5} boxShadow="lg">
                    <CardBody mt={2} mb={2}>
                        <Image mt={-4} ml={1} boxSize='70px' src='https://i.imgur.com/7xdDokf.png' alt='VitriNni Logo' />
                        <Flex justify="right" mt={-5}>
                            <ColorModeSwitcher />
                        </Flex>

                        
                    </CardBody>
                </Card>
            </Flex>

            <Flex justify="center" mt="5">
                <Card width="100%" ml={5} mr={5} boxShadow="lg">
                    <CardBody mt={2} mb={2}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <FormLabel mt={2}>Senha</FormLabel>
                            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
         
                            <Button colorScheme='red' mt={4} onClick={handleLogin}>Acessar</Button>
                        </FormControl>
                        </CardBody>
                </Card>
            </Flex>

                </ChakraProvider>
            );
        }
        
        export default Login;
        